<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar
        left-cols="4"
        center-cols="4"
        right-cols="4"
      >
        <div
          slot="right"
          class="text-right"
        >
          <v-btn
            id="post"
            large
            block
            color="primary"
            :disabled="!dirty"
            @click="upload"
          >
            Post
          </v-btn>
        </div>
      </TheNavAppBar>
    </template>

    <v-container
      slot="default"
      fluid
      class="mx-0 pa-0 mt-4"
    >
      <div class="mb-3 d-flex mx-2">
        <v-avatar class="mr-2">
          <img :src="currentUser.avatar">
        </v-avatar>
        <div>
          <div class="font-weight-bold">
            <a>{{ currentUser.username }}</a>
          </div>

          <PostPrivacyEditSheet v-model="privacy" />
        </div>
      </div>

      <v-divider />

      <v-textarea
        flat
        no-resize
        placeholder="What's on your mind?"
        rows="4"
        solo
        @input="text = $event"
      />

      <v-list
        v-if="!video"
        dense
      >
        <v-divider />
        <v-list-item
          id="upload-image-btn"
          @click="$refs.imageInput.click()"
        >
          <v-list-item-icon>
            <v-icon color="blue">
              mdi-image
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Photo
            </v-list-item-title>
          </v-list-item-content>
          <input
            ref="imageInput"
            type="file"
            accept="image/*"
            multiple
            style="display: none"
            @change="setImages"
          >
        </v-list-item>

        <v-divider />

        <v-list-item
          id="upload-video-btn"
          :disabled="!!images.length"
          @click="$refs.videoInput.click()"
        >
          <v-list-item-icon>
            <v-icon
              :color="!!images.length ? 'grey lighten-1': 'grey darken-1'"
            >
              mdi-video-vintage
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Video
            </v-list-item-title>
          </v-list-item-content>
          <input
            ref="videoInput"
            type="file"
            accept="video/*"
            style="display: none"
            @change="setVideo"
          >
        </v-list-item>

        <v-divider />
      </v-list>

      <v-container
        v-if="video"
        fluid
      >
        <v-card
          outlined
        >
          <div v-if="videoMediaError">
            <v-card-title>
              <v-icon left>
                mdi-video-vintage
              </v-icon>
              Preview unavailable
            </v-card-title>
            <v-card-subtitle>
              {{ video.file.name }}
            </v-card-subtitle>

            <v-card-text>
              The selected video has a format of type
              <code>{{ video.file.type }}</code> and cannot be previewed
              in this browser. You may still attempt to upload it to SG,
              or convert to a format supported by your browser.
            </v-card-text>
          </div>

          <v-responsive
            class="video-container"
            width="100%"
          >
            <div v-if="!videoMediaError">
              <VideoPlayer
                v-if="!videoMediaError"
                :options="videoOptions"
                @error="playerErrorHandler"
              />
            </div>
          </v-responsive>
          <v-btn
            active-class="remove-media"
            outlined
            x-small
            icon
            class="remove-media"
            color="grey"
            @click="clearVideo"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card>
      </v-container>

      <v-container
        v-if="images.length"
        id="image-grid"
        fluid
      >
        <draggable
          v-model="images"
          class="row row--dense"
        >
          <v-col
            v-for="(image, index) in images"
            :key="index"
            cols="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="ml-1 mb-1"
                :hover="hover"
                outlined
              >
                <v-img
                  class="orientation-from-image"
                  :src="image.src"
                  alt=""
                  width="100%"
                  :aspect-ratio="1"
                />
                <v-btn
                  active-class="remove-media"
                  outlined
                  x-small
                  icon
                  class="remove-media"
                  color="grey"
                  @click="images.splice(index, 1)"
                >
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-card>
            </v-hover>
          </v-col>
        </draggable>
      </v-container>
    </v-container>

    <ConfirmPageLeave :dirty="dirty" />

    <div slot="bottom-nav" />
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

import { Post } from 'pwa/models';

export default {
  name: 'TheNewPostPage',

  props: {
    files: {
      type: FileList,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      text: '',
      error: false,
      images: [],
      privacy: Post.getters('defaultPrivacy'),
      postComplete: false,
      video: null,
      videoMediaError: null,
      videoOptions: {},
    };
  },

  computed: {
    dirty() {
      if (this.postComplete) {
        return false;
      }
      return !!this.images.length || !!this.text.trim().length || !!this.video;
    },

    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    this.images = this.toList(this.files);
  },

  methods: {
    async clearVideo() {
      this.video = null;
      this.videoOptions = {};
      this.videoMediaError = false;

      if (this.$refs.videoInput) {
        this.$refs.videoInput.value = null;
        this.$refs.videoInput.files = null;
      }

      await this.$nextTick();
    },

    setImages(event) {
      const { files } = event.target;
      this.images = this.images.concat(this.toList(files));
    },

    async setVideo(event) {
      const { files } = event.target;
      const [file] = files;
      this.video = null;
      await this.$nextTick();
      this.video = this.createPreviewObject(file);

      this.videoOptions = {
        controls: true,
        fluid: true,
        sources: [{
          src: this.video.src,
          type: file.type,
        }],
        html5: {
          playsinline: true,
        },
      };
    },

    createPreviewObject: (file) => ({
      file,
      src: window.URL.createObjectURL(file),
    }),

    toList(fileList) {
      return Object.values(fileList || {}).map(this.createPreviewObject);
    },

    async upload() {
      const promise = this._uploadHandler.bind(this)();

      try {
        // Perform upload & wrap in loading state
        await this.$store.dispatch('loadingState/activate', promise);
        this.postComplete = true;
        await this.$nextTick();
        this.$router.push({ name: 'index' });
      } catch (e) {
        this.error = true;
        throw e;
      }
    },

    /**
     * Handles image upload and creates post on API
     */
    async _uploadHandler() {
      let keys = [];
      let files = [];
      let images = [];
      let video = null;
      const { privacy, text } = this;

      if (this.video) {
        files.push(this.video);
      }
      if (this.images) {
        files = files.concat(this.images);
      }

      if (files.length) {
        keys = await this.$store.dispatch('s3/uploadFiles', files);
        images = this.images.length ? keys : [];
        video = this.video ? keys[0] : null;
      }

      try {
        const data = {
          images,
          privacy,
          text,
          video,
        };
        await Post.dispatch('$create', data);
      } catch (e) {
        this.$toast('Post failed to upload', this.$toast.ERROR);
        throw e;
      }
      this.$toast('Post uploaded!', this.$toast.SUCCESS);
    },

    playerErrorHandler(error) {
      // VideoJS MediaError has code 4
      if (error.code === 4) {
        this.videoMediaError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-media {
  background: map-get($shades, 'white') !important;
  top: -8px;
  left: -8px;
  position: absolute;
}
</style>
