import { Model } from '@vuex-orm/core';

import Stream from 'pwa/models/Stream';

const colorMap = {
  hopeful: 'deep-purple lighten-3',
  photographer: 'orange lighten-4',
  staff: 'light-green lighten-2',
  suicidegirl: 'primary',
  member: 'grey darken-2',
};

const textColorMap = {
  hopeful: 'deep-purple--text text--lighten-3',
  photographer: 'orange--text text--lighten-4',
  staff: 'light-green--text text--lighten-2',
  suicidegirl: 'primary--text',
  member: 'grey--text text--darken-2',
};

export default class User extends Model {
  static entity = 'users';

  static fields() {
    let posts;
    let profile;

    if (!this.store()) {
      posts = this.attr();
      profile = this.attr();
    } else {
      posts = this.belongsToMany(
        'posts',
        'postUserAbout',
        'post_id',
        'user_id',
      );
      profile = this.hasOne('profiles', 'user_id');
    }

    return {
      id: this.number(null),
      avatar: this.string(''),
      balance: this.number(0),
      date_joined: this.string(),
      email: this.string(''),
      first_name: this.string(''),
      follow_url: this.string(''),
      follower_count: this.number(0),
      following_status: this.string('not_following'),
      following_count: this.number(0),
      full_name: this.string(''),
      hide_url: this.string(''),
      is_authenticated: this.boolean(false),
      is_hidden: this.boolean(false),
      is_staff: this.boolean(false),
      last_name: this.string(''),
      location: this.string(''),
      posts_url: this.string(''),
      present: this.boolean(false),
      profile_image: this.attr({}),
      status: this.string('pending'),
      tips_url: this.string('').nullable(),
      unfollow_url: this.string(''),
      unhide_url: this.string(''),
      url: this.string(''),
      username: this.string(''),
      user_type: this.string(),
      user_type_display: this.string(),

      profile,
      posts,
    };
  }

  async $follow() {
    const store = this.$store();
    const client = store.getters['api/client'];
    const response = await client.post(this.follow_url);
    this.following_status = response.data.status;
    this.$save();
  }

  async $unfollow() {
    const client = this.$store().getters['api/client'];
    await client.post(this.unfollow_url);
    this.following_status = 'not_following';
    this.$save();
  }

  async $hide() {
    const store = this.$store();
    const client = store.getters['api/client'];
    await client.post(this.hide_url);
    this.is_hidden = true;
    this.$save();
  }

  async $unhide() {
    const client = this.$store().getters['api/client'];
    await client.post(this.unhide_url);
    this.is_hidden = false;
    this.$save();
  }

  get $route() {
    const { username } = this;
    return { name: 'profile', params: { username } };
  }

  get color() {
    return colorMap[this.user_type] || colorMap.member;
  }

  get textColor() {
    return textColorMap[this.user_type] || textColorMap.member;
  }

  get streaming() {
    return !!Stream.query().where('user_id', this.id).all().length;
  }
}

User.entity = 'users';
