import { Model } from '@vuex-orm/core';

/**
 * Pivot model ("through table") for M2M relationship between
 * Notifications and User models
 */
export default class NotificationActor extends Model {
  static entity = 'notificationActors';

  static primaryKey = ['user_id', 'notification_id'];

  static fields() {
    return {
      user_id: this.attr(null),
      notification_id: this.attr(null),
    };
  }
}
