import { Model } from '@vuex-orm/core';

const ICON_MAP = new Map([
  ['FAILED', 'mdi-alert-circle-outline'],
  ['LIKED', 'mdi-heart-outline'],
  ['COMMENT', 'mdi-comment-outline'],
  ['MENTION', 'mdi-comment-outline'],
  ['TESTIMONIAL', 'mdi-comment-outline'],
  ['ALBUM', 'mdi-camera-outline'],
  ['PHOTO', 'mdi-camera-outline'],
  ['SOTD', 'mdi-camera-outline'],
  ['TIP', 'mdi-gift-outline'],
  ['FOLLOW', 'mdi-account-outline'],
  ['CASH_OUT', 'mdi-currency-usd'],
  ['BONUS', 'mdi-currency-usd'],
  ['FUND', 'mdi-currency-usd'],
  ['REFERRAL', 'mdi-account-outline'],
  ['APPROVED', 'mdi-check-outline'],
  ['VIDEO', 'mdi-video-outline'],
]);

export default class Notification extends Model {
  static entity = 'notifications';

  static fields() {
    return {
      id: this.number(null),
      avatar: this.attr('', (value) => value || ''),
      markup: this.string(),
      action: this.string(),
      read: this.boolean(true),
      type: this.attr(),
      created: this.string(),
      actors: this.belongsToMany(
        'users',
        'notificationActors',
        'user_id',
        'notification_id',
      ),
    };
  }

  /**
   * Returns and mdi-icon value for this notification based
   * on the action type
   */
  get icon() {
    const { action } = this;
    /* eslint-disable no-restricted-syntax */
    for (const [type, icon] of ICON_MAP) {
      if (action.includes(type)) {
        return icon;
      }
    }

    return 'mdi-square';
  }
}
