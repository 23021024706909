<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar
        left-cols="auto"
        center-cols="auto"
        right-cols="0"
      >
        <div
          v-if="otherParticipants.length"
          slot="center"
          class="ml-1"
        >
          <Avatar
            class="ml-1 mr-1"
            size="36"
            :user="otherParticipants[0]"
          />

          <router-link
            class="text-decoration-none"
            :to="otherParticipants[0].$route"
          >
            <span class="font-weight-bold">
              {{ otherParticipants[0].username }}
            </span>
          </router-link>
        </div>
      </TheNavAppBar>
    </template>

    <v-container class="fill-height d-flex align-end">
      <v-container
        v-if="conversation"
        slot="default"
        class="pb-7"
      >
        <v-row>
          <v-col>
            <infinite-loading
              direction="top"
              class="pb-3"
              @infinite="loadNext"
            >
              <div
                slot="no-more"
                class="text-caption"
              >
                <v-chip
                  outlined
                  class="font-weight-thin"
                >
                  Beginning of conversation
                </v-chip>
              </div>
            </infinite-loading>
          </v-col>
        </v-row>
        <v-row
          v-for="(message, index) in messages"
          :key="message.id"
          no-gutters
        >
          <v-col>
            <MessageBubble
              class="ml-1 mr-1"
              :text="message.text"
              :date="new Date(message.created)"
              :align="currentUser.id === message.sender.id ? 'right': 'left'"
              :shaded="currentUser.id === message.sender.id"
              :show-avatar="currentUser.id !== message.sender.id"
              :sender="message.sender"
              :show-date="showDate(index)"
            />
          </v-col>
        </v-row>
        <div class="d-flex mt-2 mb-5 indicator-container">
          <v-expand-transition @enter="scrollDownIfNearBottom">
            <div
              v-show="conversation && conversation.typing"
              class="justify-start mb-4"
            >
              <UserTypingIndicator />
            </div>
          </v-expand-transition>
        </div>
      </v-container>
    </v-container>

    <div slot="bottom-nav">
      <v-bottom-navigation
        fixed
        height="80"
        class="d-flex align-center justify-center"
      >
        <div
          v-if="conversation && conversation.blocked"
          class="blocked"
        >
          <div class="text-center">
            <v-icon size="20">
              mdi-block-helper
            </v-icon>
          </div>
          Conversation has been blocked
        </div>

        <TextAreaForm
          v-else
          class="pl-2 pr-2"
          placeholder="Write a message..."
          :submit-action="sendMessage.bind(this)"
          @input="sendTypingEvent"
        />
      </v-bottom-navigation>
    </div>
  </TheBaseLayout>
</template>

<script>
import { throttle } from 'lodash-es';
import { mapGetters } from 'vuex';

import { APIError } from 'pwa/exceptions';
import { Conversation, Message } from 'pwa/models';

export default {
  name: 'TheConversationPage',

  props: {
    /**
     * The conversation ID (existing conversations)
     */
    id: {
      type: Number,
      required: false,
      default: () => null,
    },

    /**
     * An array of participants (for new conversations)
     */
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      loadingTop: false,
    };
  },

  computed: {
    conversation() {
      const { id } = this;
      if (!id) {
        return null;
      }
      return Conversation.query().with('participants').find(id);
    },

    messages() {
      if (!this.conversation) {
        return [];
      }
      return Message.query()
        .with('sender')
        .where('conversation', this.conversation.id)
        .orderBy('created')
        .all();
    },

    read() {
      if (!this.conversation) {
        return null;
      }
      return this.conversation.read;
    },

    messageCount() {
      return this.messages.length;
    },

    /**
     * Internal value for participants filters the current user out.
     * Uses the conversation participants for existing conversations &
     * falls back on the prop value.
     */
    otherParticipants() {
      const { participants } = this.conversation ? this.conversation : this;
      return (participants || []).filter((p) => p.id !== this.currentUser.id);
    },

    ...mapGetters('auth', ['currentUser']),
  },

  watch: {
    async messageCount() {
      await this.$nextTick();

      if (!this.loadingTop) {
        this.$vuetify.goTo(document.body.scrollHeight);
      }
      this.loadingTop = false;
    },

    read(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.conversation.markRead();
      }
    },
  },

  async created() {
    if (this.id) {
      await this.getConversationOr404();
      if (this.conversation && !this.conversation.read) {
        this.conversation.markRead();
      }
    }
    await this.$nextTick();
    this.$vuetify.goTo(document.body.scrollHeight);
  },

  methods: {
    async loadNext($state) {
      if (this.messageCount) {
        this.loadingTop = true;
      }
      try {
        const data = await this.conversation.loadMoreMessages();
        $state.loaded();

        if (!data.next) {
          $state.complete();
        }
      } catch (e) {
        $state.error();
        throw e;
      }
    },

    async sendMessage(text) {
      await Message.dispatch('$create', {
        conversation: this.id,
        text,
      });
    },

    /**
     * Gets conversation using `id` prop or sends to 404
     */
    async getConversationOr404() {
      if (this.conversation) {
        return;
      }

      try {
        const { id } = this;
        await Conversation.dispatch('$get', { id });
      } catch (e) {
        if (e instanceof APIError && e.response.status === 404) {
          await this.$router.replace({ name: 'notFound' });
          return;
        }
        throw e;
      }
    },

    /**
     * Returns true if the time elapsed between the current
     * and previous message exceeds a certain threshold to determine
     * whether or not to display the date above the message
     */
    showDate(index) {
      if (index === 0) {
        return true;
      }
      const last = new Date(this.messages[index - 1].created);
      const current = new Date(this.messages[index].created);
      return (current - last) > 3 * 60 * 60 * 1000;
    },

    /**
     * Sends typing event to API no more than every 4 seconds
     */
    sendTypingEvent: throttle(
      function sendTypingEvent() { this.conversation.sendTypingEvent(); },
      4000,
      { trailing: false },
    ),

    scrollDownIfNearBottom() {
      const { scrollHeight } = document.body;
      const position = window.scrollY + window.visualViewport.height;
      if ((scrollHeight - position) < 200) {
        this.$vuetify.goTo(scrollHeight);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator-container {
  margin-left: 4em;
}
</style>
