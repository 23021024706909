import { Model } from '@vuex-orm/core';

export default class ConversationParticipant extends Model {
  static entity = 'conversationParticipants';

  static primaryKey = ['user_id', 'conversation_id'];

  static fields() {
    return {
      user_id: this.attr(null),
      conversation_id: this.attr(null),
    };
  }
}
