<template>
  <div class="page">
    <Stream
      v-if="userValid"
      :username="username"
    />
  </div>
</template>

<script>
import { APIError, UserBlockedError } from 'pwa/exceptions';
import { Stream, User } from 'pwa/models';

/**
 * The View Stream Page
 *
 * @displayName The View Stream Page
 */
export default {
  props: {
    /**
     * The username of the user's stream to load.
     */
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      userValid: false,
    };
  },

  async created() {
    if (Stream.getters('activeForUser')(this.username)) {
      this.userValid = true;
      return;
    }

    try {
      await User.dispatch('$get', { username: this.username });
    } catch (e) {
      if (e instanceof UserBlockedError) {
        this.$router.replace({
          name: 'blocked',
          params: { username: this.username },
        });
        return;
      }
      if (e instanceof APIError && e.response.status === 404) {
        this.$router.replace({ name: 'notFound' });
        return;
      }
      throw e;
    }

    this.userValid = true;
    Stream.dispatch('$refresh');
  },
};
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  position: fixed;
  width: 100%;
}
</style>
