import { Model } from '@vuex-orm/core';

export default class Comment extends Model {
  static entity = 'comments';

  static fields() {
    return {
      id: this.number(null),
      url: this.string(),
      user_id: this.number(),
      user: this.hasOne('users', 'id', 'user_id'),
      text: this.string(),
      liked: this.boolean(false),
      like_url: this.string(),
      likes_url: this.string(),
      created: this.string(),

      commentable_id: this.attr(null),
      commentable_type: this.attr(null),
    };
  }

  /**
   * Adds "like" to object.
   */
  async $like() {
    this.liked = true;

    const client = this.$store().getters['api/client'];
    const response = await client.post(this.likes_url);
    this.like_url = response.data.url;
    this.$save();
  }

  /**
   * Removes "like" from object.
   */
  async $unlike() {
    if (!this.liked || !this.like_url) {
      return;
    }

    this.liked = false;

    const client = this.$store().getters['api/client'];
    await client.delete(this.like_url);
    this.like_url = null;
    this.$save();
  }
}
