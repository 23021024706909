/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core';

export default class Join extends Model {
  static entity = 'joins';

  static fields() {
    return {
      id: this.number(null),
      url: this.string(),
      username: this.string(),
      email: this.string(),
      pay_url: this.string(),
      created: this.string(),
    };
  }

  async $pay(payload) {
    const client = this.$store().getters['api/client'];
    return client.post(this.pay_url, payload);
  }
}
