export const ERROR = 'error';
export const INFO = 'info';
export const SUCCESS = 'success';
export const WARNING = 'warning';

/**
 * Exposes $toast on the Vue instance
 */
export default {
  install(Vue) {
    Vue.prototype.$toast = function $toast(html, type = 'info', options = {}) {
      this.$store.dispatch('toast/open', { html, type, options });
    };

    Vue.prototype.$toast.ERROR = ERROR;
    Vue.prototype.$toast.INFO = INFO;
    Vue.prototype.$toast.SUCCESS = SUCCESS;
    Vue.prototype.$toast.WARNING = WARNING;
  },
};
