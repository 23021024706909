import VueChatScroll from 'vue-chat-scroll';
import InfiniteLoading from 'vue-infinite-loading';
import VuePluralize from 'vue-pluralize';
import VueLogger from 'vuejs-logger';

import ToastPlugin from './ToastPlugin';

import {
  InfiniteLoadingError,
  InfiniteLoadingNoMore,
  InfiniteLoadingNoResults,
  InfiniteLoadingSpinner,
} from 'pwa/components/InfiniteLoading';

export default {};

/**
 * Loads all app plugins / dependencies
 */
export function loadPlugins(Vue) {
  Vue.use(InfiniteLoading, {
    slots: {
      error: InfiniteLoadingError,
      noMore: InfiniteLoadingNoMore,
      noResults: InfiniteLoadingNoResults,
      spinner: InfiniteLoadingSpinner,
    },
  });

  Vue.use(VueChatScroll);

  Vue.use(VueLogger, {
    isEnabled: true,
    logLevel: 'debug',
    stringifyArguments: false,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
  });

  Vue.use(VuePluralize);

  Vue.use(ToastPlugin);
}
