import { Model } from '@vuex-orm/core';

export default class Plan extends Model {
  static entity = 'plans';

  static fields() {
    return {
      id: this.string(),
      default: this.boolean(false),
      display: this.string(),
      period: this.string(),
      price: this.string(),
      regular_price: this.string(),
      source: this.string(),
      special_offer: this.boolean(false),

      specialOfferCode: this.string().nullable(), // Set by UI when valid
    };
  }
}
