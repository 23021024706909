<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar>
        <div
          slot="center"
          class="text-center"
        >
          Comments
        </div>
      </TheNavAppBar>
    </template>

    <v-container
      slot="default"
      class="ma-0 pa-0"
      fluid
    >
      <div>
        <infinite-loading
          v-if="obj"
          direction="top"
          class="pb-3"
          @infinite="loadNext"
        >
          <div slot="no-more" />
          <div slot="no-results">
            <span v-if="!comments.length">
              Be the first to write something.
            </span>
          </div>
        </infinite-loading>
      </div>
      <v-card
        v-show="comments.length"
        class="comments"
        outlined
      >
        <div
          v-for="comment in comments"
          :key="comment.id"
        >
          <Comment
            :comment="comment"
            :like-action="comment.$like.bind(comment)"
            :unlike-action="comment.$unlike.bind(comment)"
          />
          <v-divider />
        </div>
      </v-card>
    </v-container>

    <div
      v-if="currentUser.is_authenticated"
      slot="bottom-nav"
    >
      <v-bottom-navigation
        fixed
        height="80"
        class="d-flex align-center justify-center"
      >
        <CommentForm
          v-if="obj"
          class="pl-1 pr-1"
          :url="obj.comments_url"
          :commentable-obj="obj"
        />
      </v-bottom-navigation>
    </div>
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

import Comment from 'pwa/components/Comment';
import CommentForm from 'pwa/components/CommentForm';
import { Comment as CommentModel, Post } from 'pwa/models';

export default {
  name: 'TheCommentPage',

  components: {
    Comment,
    CommentForm,
  },

  props: {
    /**
     * The object ID
     */
    id: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      nextUrl: null,
      obj: null,
    };
  },

  computed: {
    comments() {
      const post = Post.query().with('comments.user').find(this.id);

      if (!post) {
        return [];
      }
      return post.comments
        .sort((a, b) => new Date(a.created) - new Date(b.created));
    },
    ...mapGetters('api', ['routes']),
    ...mapGetters('auth', ['currentUser']),
  },

  async created() {
    const { id } = this;
    this.obj = Post.find(id);
    if (!this.obj) {
      await Post.dispatch('$get', { id });
      this.obj = Post.find(id);
    }

    if (!this.obj) {
      this.$router.replace({ name: 'notFound' });
    }
  },

  methods: {
    async loadNext($state) {
      const url = this.nextUrl || this.obj.comments_url;

      try {
        const data = await CommentModel.dispatch('$fetch', {
          obj: this.obj,
          url,
        });
        if (this.comments.length) {
          $state.loaded();
        }

        this.nextUrl = data.next;
        if (this.nextUrl === null) {
          $state.complete();
        }
      } catch (e) {
        $state.error();
        throw e;
      }
    },
  },
};
</script>

<style scope lang="scss">
.comments {
  margin-bottom: 100px;
}
</style>
