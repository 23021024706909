<template>
  <TheBaseLayout
    :app-bar-options="appBarOptions"
  >
    <template #app-bar-footer>
      <TheIndexFilters class="mt-1" />
    </template>

    <template #content>
      <v-container
        fluid
        class="base-container main-content pt-0"
      >
        <TheFeed v-bind="indexFeedFilters">
          <template #beforePosts>
            <TheLiveNowCard />

            <FeedCard
              v-if="showPromoCard"
              class="promo-card"
            >
              <ThePromoVideo />

              <v-card-text
                class="grey lighten-4 text-center"
              >
                <div
                  class="mx-auto"
                  style="max-width: 400px"
                >
                  <h4 class="mb-3">
                    Welcome to the SuicideGirls App!
                  </h4>

                  <p class="mb-6">
                    Members get access to hundreds of NEW nsfw photosets and
                    videos by SuicideGirlsand Hopefuls daily. The most loved
                    sets appear on the homepage as Set of the Day!
                  </p>

                  <v-btn
                    :to="{ name: 'register' }"
                    class="font-weight-bold mb-4"
                    rounded
                    color="primary"
                  >
                    Join Now
                  </v-btn>

                  <ThePromoGallery id="the-promo-gallery">
                    <template #activator="{ on, loading, noImages }">
                      <v-btn
                        v-if="!noImages"
                        class="mb-4"
                        color="primary"
                        outlined
                        rounded
                        size="small"
                        style="text-transform: unset"
                        :loading="loading"
                        @click="on"
                      >
                        Tap to view 200 FREE pictures!
                      </v-btn>
                      <span v-else>
                        Preview images are unavailable.
                      </span>
                    </template>
                  </ThePromoGallery>

                  <div>
                    <a
                      href="https://www.suicidegirls.com"
                      target="_blank"
                      class="font-weight-medium"
                    >
                      Want to view the full site? Tap here.
                    </a>
                  </div>
                </div>
              </v-card-text>
            </FeedCard>
          </template>
        </TheFeed>
      </v-container>
    </template>
  </TheBaseLayout>
</template>

<script>
import {
  startOfToday,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import hash from 'object-hash';
import { mapGetters } from 'vuex';

// Maps route names to API filter values
const CONTENT_FILTER_MAP = {
  index: { type: ['member_review_set', 'photo', 'sg_set', 'video'] },
  'index:sotds': { type: 'sg_set' },
  'index:memberReview': { type: 'member_review_set' },
  'index:photos': { type: ['photo'] },
  'index:videos': { type: ['video'] },
};

const USER_FILTER_MAP = {
  sgs: { user_type: 'suicidegirl' },
  hopefuls: { user_type: 'hopeful' },
  following: { user_type: 'followee' },
};

const generateFeedFilters = (selectedFilters) => {
  const filters = {};
  selectedFilters.forEach((filter) => {
    Object.entries(filter).forEach(([key, value]) => {
      if (!(key in filters)) {
        filters[key] = [];
      }
      if (value instanceof Array) {
        filters[key] = filters[key].concat(value);
      } else {
        filters[key].push(value);
      }
      filters[key] = filters[key].sort();
    });
  });
  return filters;
};

export default {
  name: 'TheIndexPage',

  data() {
    return {
      appBarOptions: {
        height: 95,
        hideOnScroll: true,
      },
    };
  },

  computed: {
    indexFeedFilters() {
      let filters = {};

      const contentFilters = CONTENT_FILTER_MAP[this.$route.name];
      const isSotd = this.$route.name === 'index:sotds';
      const {
        selectedSotdUserTypeFilters,
        selectedUserTypeFilters,
        userTypeFilters,
      } = this;

      const selectedUserFilters = isSotd
        ? selectedSotdUserTypeFilters
        : selectedUserTypeFilters;
      let userFilters = selectedUserFilters
        .filter((key) => key in USER_FILTER_MAP)
        .map((key) => USER_FILTER_MAP[key]);
      if (!userFilters.length) {
        userFilters = userTypeFilters.map((key) => USER_FILTER_MAP[key]);
      }

      filters = {
        ...this.orderingFilters[this.selectedOrderingFilter],
        ...generateFeedFilters(userFilters),
      };
      // Hash filters to create unique key for use in TheFeed component
      filters = { ...contentFilters, ...filters };
      return { ...filters, key: hash(filters) };
    },

    showPromoCard() {
      return (
        !this.currentUser.is_authenticated
        && this.$route.name === 'index'
      );
    },

    ...mapGetters('auth', ['currentUser']),

    today: () => startOfToday(),

    orderingFilters() {
      return {
        latest: {},
        mostLovedThisWeek: {
          created_after: subWeeks(this.today, 1).toISOString(),
          ordering: '-like_count',
        },
        mostLovedThisMonth: {
          created_after: subMonths(this.today, 1).toISOString(),
          ordering: '-like_count',
        },
        mostLovedThisYear: {
          created_after: subYears(this.today, 1).toISOString(),
          ordering: '-like_count',
        },
        mostLovedEver: {
          ordering: '-like_count',
        },
      };
    },

    ...mapGetters('entities/feeds', [
      'selectedOrderingFilter',
      'selectedSotdUserTypeFilters',
      'selectedUserTypeFilters',
      'userTypeFilters',
    ]),
  },
};
</script>
