<template>
  <v-container class="container-max-width mx-auto">
    <validation-observer
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <v-form
        v-if="selectedPlan"
        ref="observer"
        :disabled="loading"
        @submit.prevent="handleSubmit(submit)"
      >
        <v-row dense>
          <v-radio-group
            id="plan_id"
            v-model="selectedPlan"
          >
            <v-radio
              v-for="plan in plans"
              :key="plan.id"
              :value="plan"
              :class="{ 'font-weight-bold': plan.default }"
              :label="plan.display"
            />
          </v-radio-group>
        </v-row>

        <v-expand-transition>
          <v-alert
            v-if="showGlobalFail"
            id="alert-error"
            type="error"
            class="error mt-5"
          >
            {{ globalFailMessage || 'An unexpected error has occurred.' }}
          </v-alert>
        </v-expand-transition>

        <validation-provider
          v-slot="{ errors }"
          vid="name"
          name="Cardholder name"
          rules="required"
        >
          <v-text-field
            id="name"
            v-model="name"
            :error-messages="errors"
            label="Cardholder name"
            outlined
            required
          />
        </validation-provider>

        <VStripeCard
          id="stripe"
          ref="stripeCard"
          v-model="source"
          :api-key="apiKey"
          :options="{ name }"
          outlined
          required
        />

        <validation-provider
          id="tos"
          v-slot="{ errors }"
          vid="accept_tos"
          name="Terms of service"
          rules="acceptedTerms"
        >
          <v-checkbox
            v-model="accept_tos"
            :error-messages="errors"
            class="align-start"
            required
          >
            <div
              slot="label"
              class="text-body-2 grey--text"
            >
              I'm at least 18 years old or the age of consent in my
              jurisdiction. I understand that I will be charged
              {{ selectedPlan.price }} now for one {{ selectedPlan.period }}
              and {{ selectedPlan.regular_price }} every
              {{ selectedPlan.period }} thereafter until I cancel my
              subscription; and I agree to the <a
                href="https://www.suicidegirls.com/legal/"
                target="_blank"
                @click.stop
              >
                terms of service.
              </a>
            </div>
          </v-checkbox>
        </validation-provider>

        <v-row class="mt-4">
          <v-btn
            id="submit"
            :disabled="!okToSubmit() || invalid"
            :loading="loading"
            block
            class="white--text"
            color="green"
            type="submit"
            x-large
          >
            Join SuicideGirls
          </v-btn>
        </v-row>
      </v-form>
    </validation-observer>

    <ConfirmPageLeave :dirty="dirty" />
  </v-container>
</template>

<script>
import { VStripeCard } from 'v-stripe-elements/lib';
import { extend, setInteractionMode } from 'vee-validate';
import { mapGetters } from 'vuex';

import { APIError, InvalidError, PaymentRequiredError } from 'pwa/exceptions';
import { Plan, Join } from 'pwa/models';

setInteractionMode('eager');

extend(
  'acceptedTerms',
  (value) => (value === true ? true : 'You must accept the terms of service'),
);

class VerificationFailedError extends Error {}

export default {
  name: 'TheRegistrationPagePaymentStep',

  components: { VStripeCard },

  props: {
    submitAction: {
      type: Function,
      default: undefined,
    },
  },

  data() {
    return {
      apiKey: STRIPE_PUBLISHABLE_KEY,
      accept_tos: false,
      dirty: false,
      globalFailMessage: null,
      join: null,
      loading: false,
      name: '',
      selectedPlan: null,
      showGlobalFail: false,
      source: null,
    };
  },

  computed: {
    plans() {
      return Plan.query().orderBy('default', 'desc').all();
    },

    ...mapGetters('auth', ['currentUser']),
  },

  watch: {
    /**
     * Update existing Stripe token if the cardholder name has changed
     */
    name() {
      if (!this.okToSubmit()) {
        return;
      }
      this.$refs.stripeCard.processCard();
    },
  },

  async created() {
    const { specialOffer } = this.$route.query;
    if (!this.plans.length || specialOffer) {
      await Plan.dispatch('$fetch', specialOffer);
    }
    [this.selectedPlan] = this.plans;

    this.join = this.getJoin();
    if (this.join) {
      return;
    }

    await Join.dispatch('$fetch');
    this.join = this.getJoin();
    if (this.join) {
      return;
    }

    // If user is authenticated & reactivating, create attempt
    if (this.currentUser.is_authenticated) {
      const data = specialOffer ? { special_offer: specialOffer } : {};
      const { token } = this.$route.query;
      if (token) {
        data.tracking_token = token;
      }
      this.join = await Join.dispatch('$create', data);
      return;
    }

    // Redirect to initial step if no join attempt exists
    this.dirty = false;
    this.$router.replace({ name: 'register' });
  },

  methods: {
    getJoin() {
      const join = Join.query().orderBy('created', 'desc').first();
      if (join) {
        this.dirty = true;
      }
      return join;
    },

    async submit() {
      if (!this.okToSubmit()) {
        return;
      }
      this.showGlobalFail = false;
      this.showFailMessage = null;
      this.loading = true;

      const { stripeCard } = this.$refs;
      const { card, stripe } = stripeCard;
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: { name: this.name },
      });

      try {
        await this.pay(paymentMethod);
        this.dirty = false;
        await this.$nextTick();

        const route = (
          this.$route.query.next
          || { name: 'index' }
        );

        this.$router.replace(route);

        // Reload app as new & authenticated user
        await this.$store.dispatch('reset');
        await this.$store.dispatch('loadApp');
        this.$toast('Thank you & welcome to SuicideGirls!');
      } catch (e) {
        this.showGlobalFail = true;
        if (e instanceof InvalidError) {
          if (Object.keys(e.errors).length) {
            this.$refs.observer.setErrors(e.errors);
            return;
          }
          this.globalFailMessage = e.message;
        } else if (e instanceof VerificationFailedError) {
          this.globalFailMessage = e.message;
        } else if (!(e instanceof APIError)) {
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },

    async pay(paymentMethod) {
      // eslint-disable-next-line camelcase
      const { accept_tos, name, selectedPlan } = this;
      const { stripeCard } = this.$refs;
      const { stripe } = stripeCard;
      const action = this.submitAction || this.join.$pay.bind(this.join);

      try {
        await action({
          // eslint-disable-next-line camelcase
          accept_tos,
          name,
          plan_id: selectedPlan.id,
          special_offer: selectedPlan.specialOfferCode,
          stripe_payment_method: paymentMethod.id,
        });
      } catch (e) {
        if (!(e instanceof PaymentRequiredError)) {
          throw e;
        }
        const clientSecret = e.response.data.client_secret;
        const result = await stripe.confirmCardPayment(
          clientSecret, {
            payment_method: paymentMethod.id,
          },
        );
        // User declined / verification failed
        if (result.error) {
          throw new VerificationFailedError(result.error.message);
        }
        // Retry API request once confirmed
        await this.pay(paymentMethod);
      }
    },

    /**
     * Return true if the stripe component is "ok to submit".
     * This must be a method (and not computed) as $refs are
     * not watched.
     */
    okToSubmit() {
      if (!this.$refs.stripeCard) return false;
      return this.$refs.stripeCard.okToSubmit;
    },
  },
};
</script>

<style lang="scss" scoped>
// Override checkbox position relative to label text
#tos ::v-deep .v-input__slot {
  align-items: flex-start !important;
}
</style>
