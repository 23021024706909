<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar>
        <div
          slot="center"
          class="text-center"
        >
          Preferences
        </div>
      </TheNavAppBar>
    </template>

    <template #content>
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        width="2"
      />

      <v-container
        class="container-max-width mx-auto"
        fluid
      >
        <div
          v-if="!loading"
          class="text-left"
        >
          <div
            v-for="(preference, index) in preferences"
            :key="index"
          >
            <h4>{{ preference.label }}</h4>

            <v-slider
              class="my-2 text-body-2 font-weight-medium"
              :loading="preference.loading"
              :max="preference.choices.length - 1"
              :tick-labels="getTickLabels(preference)"
              :value="getIndexValue(preference)"
              min="0"
              tick-size="4"
              ticks="always"
              @input="onSlide($event, preference)"
            />
          </div>
        </div>
      </v-container>
    </template>
  </TheBaseLayout>
</template>

<script>
export default {
  name: 'ThePreferencesPage',

  data() {
    return {
      client: this.$store.getters['api/client'],
      loading: false,
      preferences: [],
      url: this.$store.getters['api/routes'].preferences,
    };
  },

  async created() {
    this.loading = true;
    const response = await this.client.get(this.url);
    this.preferences = response.data;
    this.loading = false;
  },

  methods: {
    getIndexValue(preference) {
      return preference.choices
        .findIndex((choice) => choice.value === preference.value);
    },

    getTickLabels(preference) {
      return preference.choices.map((choice) => choice.label);
    },

    async onSlide(index, preference) {
      const choice = preference.choices[index];
      const { value } = choice;
      await this.client.patch(this.url, { [preference.type]: value });
    },
  },
};
</script>
