import { Model } from '@vuex-orm/core';

import Album from './Album';
import BlogPost from './BlogPost';
import Photoset from './Photoset';
import StatusUpdate from './StatusUpdate';
import Video from './Video';

// Maps Django content type names to Vuex ORM models
const contentTypeMap = {
  album: Album,
  blogpost: BlogPost,
  photoset: Photoset,
  statusupdate: StatusUpdate,
  video: Video,
};

export default class Post extends Model {
  static entity = 'posts';

  static fields() {
    return {
      id: this.number(null),
      url: this.string(''),
      action: this.string(),
      comment_count: this.number(0),
      comments_url: this.string(),
      content_type: this.number(),
      content_type_display: this.string(''),
      content_type_name: this.string(),
      created: this.string(),
      is_editable: this.boolean(false),
      like_count: this.number(0),
      like_url: this.string(),
      liked: this.boolean(false),
      likes_url: this.string(),
      object_id: this.attr(),
      privacy: this.string(''),
      tips_url: this.string(null).nullable(),
      users_about: this.belongsToMany(
        'users',
        'postUserAbout',
        'user_id',
        'post_id',
      ),

      comments: this.morphMany(
        'comments',
        'commentable_id',
        'commentable_type',
      ),
    };
  }

  get $route() {
    const { id } = this;
    return { name: 'postDetail', params: { id } };
  }

  get contentObject() {
    if (!(this.content_type_name in contentTypeMap)) {
      return null;
    }
    const ContentModel = contentTypeMap[this.content_type_name];
    return ContentModel.find(this.object_id);
  }

  get privacyIcon() {
    return this.$getters('privacyIcons')[this.privacy];
  }

  async $update(data = {}) {
    const client = this.$store().getters['api/client'];
    const response = await client.patch(this.url, data);
    return this.$dispatch('update', { data: response.data });
  }

  /**
   * Adds "like" to object.
   */
  async $like() {
    const client = this.$store().getters['api/client'];
    const response = await client.post(this.likes_url);
    this.like_url = response.data.url;
    this.liked = true;
    this.like_count += 1;
    this.$save();
  }

  /**
   * Removes "like" from object.
   */
  async $unlike() {
    if (!this.liked || !this.like_url) {
      return;
    }

    // Make API request
    const client = this.$store().getters['api/client'];
    await client.delete(this.like_url);
    this.liked = false;
    this.like_count -= 1;
    this.like_url = null;
    this.$save();
  }
}
