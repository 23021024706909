import { Model } from '@vuex-orm/core';
import { v4 as uuid4 } from 'uuid';

export class UserFeedUser extends Model {
  static entity = 'userFeedUsers';

  static primaryKey = ['user_id', 'user_feed_id'];

  static fields() {
    return {
      user_id: this.attr(null),
      user_feed_id: this.attr(null),
    };
  }
}

export class UserFeed extends Model {
  static entity = 'userFeeds';

  static fields() {
    return {
      id: this.attr(() => uuid4()),
      url: this.string(null).nullable(),
      _objects: this.belongsToMany(
        'users',
        UserFeedUser,
        'user_feed_id',
        'user_id',
      ),

      // API Filters
      followers_of: this.attr(null).nullable(),
      followees_of: this.attr(null).nullable(),
      following: this.attr(null).nullable(),
      ordering: this.attr(null).nullable(),
      user_type: this.attr(null).nullable(),

      nextUrl: this.string(null).nullable(),

      // Used to throttle requests
      _pending: this.attr(null).nullable(),
    };
  }

  /**
   * Use a getter to fetch related objects to compensate
   * for the belongsToMany not updating in real-time
   */
  get objects() {
    const feed = this.$query()
      .with(['_objects'])
      .find(this.id);
    if (!feed) {
      return [];
    }
    return feed._objects;
  }

  async $fetch() {
    // If an existing request for this feed is pending, return and ignore
    // this call.
    if (this._pending) {
      return this._pending;
    }

    const request = this.$store().dispatch(
      'entities/users/$fetch', {
        url: this.nextUrl || this.url,
        feed: this,
        followers_of: this.followers_of,
        followees_of: this.followees_of,
        following: this.following,
        ordering: this.ordering,
        user_type: this.user_type,
      },
    );

    try {
      this._pending = true;
      const data = await request;
      this.nextUrl = data.next;
    } finally {
      this._pending = false;
      this.$save();
    }
    return request;
  }
}
