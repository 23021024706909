import { Model } from '@vuex-orm/core';

export default class UserRecommendation extends Model {
  static entity = 'userRecommendations';

  static fields() {
    return {
      id: this.number(null),

      user: this.belongsTo('users', 'user_id'),
      designee: this.belongsTo('users', 'designee_id'),
      designee_id: this.number(null),
      user_id: this.number(null),
    };
  }
}
