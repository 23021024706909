import { Model } from '@vuex-orm/core';

export default class Message extends Model {
  static entity = 'messages';

  constructor(object) {
    super(object);
    // Save user_id on create
    this.user_id = this.user_id || object.sender.id;
  }

  static fields() {
    return {
      id: this.number(null),
      url: this.string(),
      conversation: this.number(),
      sender: this.belongsTo('users', 'user_id'),
      user_id: this.number(),
      text: this.string(),
      truncated: this.boolean(false),
      created: this.string(),
    };
  }
}
