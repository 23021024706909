import { Model } from '@vuex-orm/core';

import { getConstrainedAspectRatio } from 'pwa/utils/media';

export default class Photoset extends Model {
  static entity = 'photosets';

  static fields() {
    return {
      id: this.number(null),
      cover_image: this.attr({}),
      image_count: this.number(0),
      images: this.attr([]),
      name: this.string(),
      photographer_name: this.string(null).nullable(),
      photographer_url: this.string(null).nullable(),
      text: this.string(null).nullable(),
    };
  }

  get feedAspectRatio() {
    const { width, height } = this.cover_image || {};
    if (width && height) {
      return getConstrainedAspectRatio(width, height);
    }
    return 16 / 9;
  }
}
