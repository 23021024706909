<template>
  <TheBaseLayout
    :app-bar-options="{ app }"
    :bottom-nav-options="{ app }"
  >
    <div
      v-if="!showAppBar"
      slot="app-bar"
    />

    <template #content>
      <ThePromoVideo
        v-if="showPromoVideo"
        id="promo-video"
      />

      <v-container
        v-if="showSocialButtons"
        class="container-max-width mx-auto pb-0"
        fluid
      >
        <v-btn
          id="google-btn"
          :disabled="loading"
          :href="googleUrl"
          block
          class="py-7 mb-4"
          color="white"
          elevation="4"
          large
          tile
        >
          <v-icon
            style="position: absolute; left: 10px"
            left
          >
            $google
          </v-icon>
          Continue with Google
        </v-btn>

        <v-btn
          id="twitter-btn"
          :disabled="loading"
          :href="twitterUrl"
          block
          class="py-7 mb-4"
          color="white"
          elevation="4"
          large
          tile
        >
          <v-icon
            style="position: absolute; left: 10px"
            left
          >
            $twitter
          </v-icon>
          Continue with Twitter
        </v-btn>

        <slot name="extraSocial" />
      </v-container>

      <slot name="default" />
    </template>
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheRegistrationLayout',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    showPromoVideo: {
      type: Boolean,
      default: true,
    },

    showSocialButtons: {
      type: Boolean,
      default: true,
    },

    showAppBar: {
      type: Boolean,
      default: true,
    },

    /**
     * Set to false to disable app/fixed binding. Use
     * for styleguide.
     */
    app: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    nextUrl() {
      const route = (
        this.$route.query.next
        || this.$router.resolve({ name: 'index' }).href
      );
      return (new URL(route, document.location.href)).href;
    },

    paymentUrl() {
      const payment = this.$router.resolve({ name: 'register-payment' });
      const url = new URL(payment.href, document.location.href);
      const params = { ...this.$route.query };
      const { specialOffer } = this.$route.params;
      if (specialOffer) {
        params.specialOffer = specialOffer;
      }
      Object.entries(params).forEach((p) => url.searchParams.append(...p));
      return url.href;
    },

    googleUrl() {
      const google = this.$store.getters['api/routes'].google_login;
      if (!google) {
        return '';
      }
      const url = new URL(google);
      url.searchParams.append('next', this.nextUrl);
      url.searchParams.append('payment_url', this.paymentUrl);
      url.searchParams.append('prompt', 'select_account');
      return url.href;
    },

    twitterUrl() {
      const twitter = this.$store.getters['api/routes'].twitter_login;
      if (!twitter) {
        return '';
      }
      const url = new URL(twitter);
      url.searchParams.append('next', this.nextUrl);
      url.searchParams.append('payment_url', this.paymentUrl);
      return url.href;
    },

    ...mapGetters('router', ['history']),
  },

  methods: {
    back() {
      const history = [...this.history].reverse();
      const [lastRoute] = history;
      const regPages = ['login', 'register'];
      if (regPages.includes(lastRoute.name)) {
        const route = history.find((r) => !regPages.includes(r.name));
        return this.$router.push(route || { name: 'index' });
      }
      return this.$store.dispatch('router/back', this.$router);
    },
  },
};
</script>
