<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar>
        <div
          slot="center"
          class="text-center text-capitalize"
        >
          {{ title }}
        </div>
      </TheNavAppBar>
    </template>

    <div class="text-center">
      <v-progress-circular
        v-if="!post"
        class="mt-3"
        indeterminate
        color="primary"
        width="2"
      />
    </div>

    <v-container
      class="pa-0"
      fluid
    >
      <Post
        v-if="post"
        :post="post"
        detail-view
      />
    </v-container>
  </TheBaseLayout>
</template>

<script>
import { NotFoundError } from 'pwa/exceptions';
import { Post } from 'pwa/models';

/**
 * The Post Detail Page.
 *
 * @displayName The Post Detail Page
 */
export default {

  async beforeRouteUpdate(to, from, next) {
    await this.initPost(to.params.id);
    next();
  },

  props: {
    /**
     * The object ID
     */
    id: {
      type: Number,
      required: true,
    },

    /**
     * The content type ID
     */
    contentType: {
      type: Number,
      default: null,
    },
  },

  computed: {
    post() {
      return Post.getters('getLoadedObject')(this.id);
    },

    title() {
      if (!this.post || !this.post.content_type_display) {
        return '';
      }

      return this.post.content_type_display;
    },
  },

  created() {
    if (this.contentType) {
      return this.handleContentTypeRequest();
    }
    return this.initPost(this.id);
  },

  methods: {
    async handleContentTypeRequest() {
      try {
        const post = await Post.dispatch('$getFromContentObject', {
          content_type: this.contentType,
          object_id: this.id,
        });

        this.$router.replace({
          name: 'postDetail',
          params: { id: post.id },
        });
        return;
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.$router.replace({ name: 'notFound' });
          return;
        }
        throw e;
      }
    },

    async initPost(id) {
      if (!this.post) {
        try {
          await Post.dispatch('$get', { id });
        } catch (e) {
          if (e instanceof NotFoundError) {
            this.$router.replace({ name: 'notFound' });
          } else {
            throw e;
          }
        }
      }
    },
  },
};
</script>
