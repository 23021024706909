<template>
  <TheRegistrationLayout :loading="loading">
    <template #extraSocial>
      <v-btn
        id="facebook-btn"
        :disabled="loading"
        :href="facebookUrl"
        block
        class="py-7"
        color="white"
        elevation="4"
        large
        target="_blank"
        tile
      >
        <v-icon
          style="position: absolute; left: 8px;"
          size="24"
          left
        >
          $facebook
        </v-icon>
        Continue with Facebook
      </v-btn>
    </template>

    <v-container
      fluid
      class="container-max-width mx-auto"
    >
      <v-row
        dense
        class="d-flex align-center my-3"
      >
        <v-col>
          <v-divider />
        </v-col>

        <v-col
          cols="auto"
          class="text-uppercase text-center font-weight-medium text-caption"
        >
          Or
        </v-col>

        <v-col>
          <v-divider />
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-alert
          v-if="error"
          type="error"
          class="error mt-5"
        >
          {{ error }}
        </v-alert>
      </v-expand-transition>

      <v-form
        id="login-form"
        :disabled="loading"
        dense
        @submit.prevent="submit"
      >
        <v-text-field
          id="username"
          v-model="username"
          autocapitalize="none"
          autocomplete="username"
          autocorrect="off"
          label="Username"
          outlined
        />
        <PasswordField
          id="password"
          v-model="password"
          autocomplete="current-password"
          outlined
        />

        <v-btn
          id="submit-btn"
          block
          x-large
          class="white--text"
          type="submit"
          color="green"
          :disabled="!valid"
          :loading="loading"
        >
          Login
        </v-btn>
      </v-form>
    </v-container>
  </TheRegistrationLayout>
</template>

<script>
import { APIError } from 'pwa/exceptions';
import { TheRegistrationLayout } from 'pwa/layouts';

/**
 * @displayName The login page
 */
export default {
  name: 'TheLoginPage',

  components: {
    TheRegistrationLayout,
  },

  props: {
    /**
     * Override the default login function. Expects async.
     * Defaults to: `auth/$login`.
     */
    loginCallback: {
      type: Function,
      required: false,
      async default(data) {
        await this.$store.dispatch('auth/$login', data);
        const route = this.$route.query.next || { name: 'index' };
        this.$router.replace(route);
        await this.$store.dispatch('reset');
        this.$store.dispatch('loadApp');
      },
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      password: '',
      showPassword: false,
      username: '',
    };
  },

  computed: {
    valid() {
      return this.username && this.password;
    },

    facebookUrl() {
      return this.$store.getters['api/routes'].facebook_login;
    },

  },

  methods: {
    async submit() {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      this.error = false;
      try {
        const { username, password } = this;
        await this.loginCallback({ username, password });
      } catch (e) {
        if (e instanceof APIError) {
          this.error = e.message;
        } else {
          this.error = 'An unknown error has occured';
          throw e;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
