import { Model } from '@vuex-orm/core';

export default class Like extends Model {
  static fields() {
    return {
      id: this.number(null),
      url: this.string(),
      user_id: this.number(),
      username: this.string(),
      avatar: this.string(),
    };
  }
}
