<template>
  <TheRegistrationLayout
    :key="step"
    :show-promo-video="step === 1"
    :show-app-bar="step === 1"
    :show-social-buttons="step === 1"
    :loading="loading"
    :app="app"
  >
    <TheRegistrationPageInitialStep
      v-if="step === 1"
      v-bind="initialStepOptions"
      @submit="loading = true"
      @done="loading = false"
    />

    <v-stepper
      v-model="currentStep"
      flat
      vertical
    >
      <v-container
        v-if="currentStep !== 1"
        class="container-max-width mx-auto"
      >
        <v-stepper-step
          :step="1"
          :complete="step > 1"
        >
          Choose username
        </v-stepper-step>

        <v-stepper-step
          :step="2"
          :complete="step > 2"
        >
          Enter payment information
        </v-stepper-step>

        <v-divider />

        <v-stepper-content
          step="2"
          class="mx-0 px-0"
        >
          <TheRegistrationPagePaymentStep
            v-bind="paymentStepOptions"
          />
        </v-stepper-content>
      </v-container>
    </v-stepper>
  </TheRegistrationLayout>
</template>

<script>
import TheRegistrationPageInitialStep from
  './TheRegistrationPageInitialStep.vue';
import TheRegistrationPagePaymentStep from
  './TheRegistrationPagePaymentStep.vue';

import { TheRegistrationLayout } from 'pwa/layouts';

/**
 * The registration page
 * @displayName The registration page
 */
export default {
  name: 'TheRegistrationPage',

  components: {
    TheRegistrationLayout,
    TheRegistrationPageInitialStep,
    TheRegistrationPagePaymentStep,
  },

  props: {
    step: {
      type: Number,
      default: 1,
    },

    initialStepOptions: {
      type: Object,
      default: undefined,
    },

    paymentStepOptions: {
      type: Object,
      default: undefined,
    },

    /**
     * Set to false to disable app/fixed binding. Use
     * for styleguide.
     */
    app: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentStep: this.step,
      loading: false,
    };
  },

  watch: {
    step(value) {
      this.currentStep = value;
    },
  },
};
</script>
