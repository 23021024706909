import { Model } from '@vuex-orm/core';

import { getConstrainedAspectRatio } from 'pwa/utils/media';

export default class Album extends Model {
  static entity = 'albums';

  static fields() {
    return {
      id: this.number(null),
      text: this.string(null).nullable(),
      image_count: this.number(0),
      images: this.attr([]),
    };
  }

  get feedAspectRatio() {
    if (this.images.length) {
      const { width, height } = this.images[0].preview;
      if (width && height) {
        return getConstrainedAspectRatio(width, height);
      }
    }
    return 4 / 3;
  }
}
