<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar
        left-cols="2"
        center-cols="10"
        right-cols="2"
      >
        <template #center>
          <UserSearch
            v-model="userSelection"
            :initial-items="initialItems"
            autofocus
            @input="focusOnMessage"
          />
        </template>
      </TheNavAppBar>
    </template>

    <template #bottom-nav>
      <v-bottom-navigation
        fixed
        height="80"
        class="d-flex align-center justify-center"
      >
        <TextAreaForm
          ref="messageForm"
          class="pl-2 pr-2"
          placeholder="Write a message..."
          :submit-action="sendMessage.bind(this)"
          :form-disabled="!participants.length"
        />
      </v-bottom-navigation>
    </template>
  </TheBaseLayout>
</template>

<script>
import TextAreaForm from 'pwa/components/TextAreaForm';
import UserSearch from 'pwa/components/UserSearch';
import Conversation from 'pwa/models/Conversation';
import Message from 'pwa/models/Message';

export default {
  components: {
    TextAreaForm,
    UserSearch,
  },

  data() {
    return {
      userSelection: null,
    };
  },

  computed: {
    initialItems() {
      const currentUser = this.$store.getters['auth/currentUser'];
      return Conversation.all().map((c) => c.participants).flat().filter(
        (p) => p.id !== currentUser.id,
      );
    },

    participants() {
      if (!this.userSelection) {
        return [];
      }
      return this.userSelection instanceof Array
        ? this.userSelection : [this.userSelection];
    },
  },

  methods: {
    async sendMessage(text) {
      const { participants } = this;
      const conversation = await Conversation.dispatch(
        '$create',
        { participants },
      );
      await Message.dispatch('$create', {
        conversation: conversation.id,
        text,
      });
      this.$router.push({
        name: 'conversationDetail',
        params: { id: conversation.id },
      });
    },

    focusOnMessage() {
      this.$refs.messageForm.$el.querySelector('textarea').focus();
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  margin: 1.5rem auto 0;
  max-width: 480px;
}
</style>
