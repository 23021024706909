<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar>
        <div
          slot="center"
          class="text-center"
        >
          <span>{{ username }}</span>
        </div>

        <div
          slot="right"
          class="text-right"
        >
          <v-btn
            v-if="user && isOwnProfile"
            id="open-profile-drawer-btn"
            icon
            @click="toggleProfileDrawer(true)"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </TheNavAppBar>
    </template>

    <template #content>
      <v-container
        id="profile-page"
        class="base-container"
        fluid
      >
        <div
          v-if="!user"
          class="text-center mt-3 mb-5"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
          />
        </div>

        <TheFeed
          v-if="user"
          :show-recommendations="false"
          v-bind="feedFilters"
        >
          <template #beforePosts>
            <v-container
              v-if="user"
              class="base-container px-4 py-3"
            >
              <v-row
                dense
                class="mb-2 align-start flex-nowrap"
              >
                <Avatar
                  :size="70"
                  :user="user"
                />

                <div class="flex-grow-1">
                  <v-container
                    fluid
                    class="pr-0 py-0 my-0"
                  >
                    <v-row dense>
                      <v-col class="py-0">
                        <h3
                          class="username"
                          :class="user.textColor"
                        >
                          {{ user.username }}
                        </h3>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      class="text-body-2 mt-1"
                    >
                      <v-col
                        class="font-weight-medium"
                      >
                        {{ user.user_type_display }} since
                        {{ memberSince }}
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="user.location"
                      no-gutters
                      class="text-body-2 mt-1"
                    >
                      <v-col>
                        <v-list-item
                          v-if="user.location"
                          class="pa-0 ma-0 font-weight-medium"
                          style="min-height: 0"
                        >
                          <v-list-item-icon
                            dense
                            class="pa-0 ma-0"
                            style="min-width: 0"
                          >
                            <v-icon
                              color="grey lighten-1"
                              small
                            >
                              mdi-map-marker
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content
                            class="pa-0 ma-0"
                          >
                            {{ user.location }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      class="text-body-2 mt-1"
                    >
                      <v-col>
                        <UserListSheet
                          class="following"
                          title="Following"
                          :load-next="loadFollowees"
                          :users="followees"
                        >
                          <template #activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              @click.prevent="
                                enforceUserEnabled(() => on.click($event))
                              "
                            >
                              <span class="font-weight-medium">
                                {{ numeral(user.following_count).format('0a') }}
                              </span>
                              <span
                                class="
                                    font-weight-medium grey--text
                                    text--lighten-1
                                "
                              >
                                Following
                              </span>
                            </a>
                          </template>
                        </UserListSheet>
                      </v-col>

                      <v-col>
                        <UserListSheet
                          class="followers"
                          title="Followers"
                          :load-next="loadFollowers"
                          :users="followers"
                        >
                          <template #activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              @click.prevent="
                                enforceUserEnabled(() => on.click($event))
                              "
                            >
                              <span class="font-weight-medium">
                                {{ numeral(user.follower_count).format('0a') }}
                              </span>
                              <span
                                class="
                                    font-weight-medium grey--text
                                    text--lighten-1
                                "
                              >
                                Followers
                              </span>
                            </a>
                          </template>
                        </UserListSheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-row>

              <v-row
                v-if="!isOwnProfile"
                class="py-3"
                dense
              >
                <v-btn
                  id="message-btn"
                  class="mr-3"
                  color="grey"
                  outlined
                  rounded
                  :loading="loadingConversation"
                  @click="enforceUserEnabled(handleMessages)"
                >
                  <v-icon>
                    mdi-email-outline
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="user.tips_url"
                  id="tip-btn"
                  class="mr-3"
                  color="grey"
                  outlined
                  rounded
                  @click="enforceUserEnabled(handleTip)"
                >
                  <v-icon>
                    mdi-gift-outline
                  </v-icon>
                </v-btn>

                <FollowButton
                  v-if="!user.is_hidden"
                  id="follow-btn"
                  class="mr-3"
                  :user="user"
                />

                <HideButton
                  v-if="user.following_status == 'not_following'"
                  id="hide-btn"
                  :user="user"
                />
              </v-row>

              <v-row
                v-if="hasSocialLinks"
                id="profile-social-links"
                dense
              >
                <v-col>
                  <v-btn
                    v-for="(link, index) in socialLinks"
                    :id="link.id"
                    :key="index"
                    :href="link.href"
                    class="mr-2"
                    color="grey"
                    small
                    fab
                    icon
                    outlined
                    target="_blank"
                  >
                    <v-icon v-text="link.icon" />
                  </v-btn>
                </v-col>
              </v-row>

              <div
                v-if="!loaded && !user"
                align="center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  width="2"
                />
              </div>

              <v-row>
                <v-tabs
                  v-model="tabSelection"
                  class="profile-tabs"
                  width="100%"
                >
                  <v-tab
                    v-for="(t, index) in tabs"
                    :key="index"
                    :to="{ name: t.to }"
                    exact
                    v-text="t.label"
                  />
                </v-tabs>
              </v-row>
            </v-container>
          </template>
        </TheFeed>
      </v-container>
    </template>
  </TheBaseLayout>
</template>

<script>
import numeral from 'numeral';
import { mapActions, mapGetters } from 'vuex';

import { NotFoundError } from 'pwa/exceptions';
import {
  Conversation,
  Feed,
  User,
  UserFeed,
} from 'pwa/models';

export default {
  name: 'TheProfilePage',

  props: {
    /**
     * The API URL for the user detail page
     */
    username: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      followeeFeed: null,
      followerFeed: null,
      loaded: false,
      loadingConversation: false,
      photographyFeed: null,
      socialMap: {
        allmylinks_url: '$all-my-links',
        instagram_url: 'mdi-instagram',
        mfc_url: '$mfc',
        onlyfans_url: '$only-fans',
        twitter_url: 'mdi-twitter',
      },
      tabSelection: 0,
    };
  },

  computed: {
    // Maps route names to API filter values
    contentFilterMap() {
      return {
        profile: { type: null, username: this.user.username },
        'profile:photosets': {
          type: ['member_review_set', 'sg_set'],
          username: this.user.username,
        },
        'profile:photos': { type: 'photo', username: this.user.username },
        'profile:videos': { type: 'video', username: this.user.username },
        'profile:blogs': {
          type: ['blog', 'status_update'],
          username: this.user.username,
        },
        'profile:photography': {
          photographer_username: this.user.username,
          type: ['member_review_set', 'sg_set'],
        },
      };
    },

    memberSince() {
      return new Date(this.user.date_joined).getFullYear();
    },

    followees() {
      if (!this.followeeFeed) {
        return [];
      }
      return this.followeeFeed.objects;
    },

    followers() {
      if (!this.followerFeed) {
        return [];
      }
      return this.followerFeed.objects;
    },

    photography() {
      return this.photographyFeed ? this.photographyFeed.posts : [];
    },

    tabs() {
      const tabs = [
        { label: 'All', to: 'profile' },
        { label: 'Photos', to: 'profile:photos' },
        { label: 'Videos', to: 'profile:videos' },
        { label: 'Blogs', to: 'profile:blogs' },
      ];

      if (['suicidegirl', 'hopeful'].includes(this.user.user_type)) {
        tabs.splice(1, 0, {
          label: 'Photosets',
          to: 'profile:photosets',
        });
      }

      if (this.showPhotographyTab) {
        tabs.push({
          label: 'Photography',
          to: 'profile:photography',
        });
      }

      return tabs;
    },

    hasSocialLinks() {
      return this.socialLinks.length > 0;
    },

    socialLinks() {
      if (!this.user || !this.user.profile) {
        return [];
      }
      const supportedKeys = Object.keys(this.socialMap);
      const socialLinks = Object.fromEntries(
        Object.entries(this.user.profile)
          .filter(([key, val]) => supportedKeys.includes(key) && val),
      );

      return Object.entries(socialLinks).map(([key, val]) => ({
        id: key,
        href: val,
        icon: this.socialMap[key],
      }));
    },

    isOwnProfile() {
      return this.user.id === this.currentUser.id;
    },

    showPhotographyTab() {
      return (
        this.user.user_type === 'photographer'
        || this.$route.name === 'profile:photography'
        || this.photography.length > 0
      );
    },

    feedFilters() {
      return (
        this.contentFilterMap[this.$route.name]
        || this.contentFilterMap.profile
      );
    },

    user() {
      if (!this.username) {
        return null;
      }
      return User.query()
        .with('profile')
        .where('username', this.username)
        .first();
    },

    ...mapGetters('auth', ['currentUser']),
  },

  async created() {
    const { username } = this;
    this.followeeFeed = await UserFeed.dispatch(
      'getOrCreate',
      { followees_of: this.username },
    );
    this.followerFeed = await UserFeed.dispatch(
      'getOrCreate',
      { followers_of: this.username },
    );
    this.photographyFeed = await Feed.dispatch(
      'getOrCreate',
      {
        photographer_username: this.username,
        type: ['member_review_set', 'sg_set'],
      },
    );
    if (!this.user || !this.user.profile) {
      try {
        await User.dispatch('$get', { username });
      } catch (e) {
        if (e instanceof NotFoundError) {
          this.$router.replace({ name: 'notFound' });
          return;
        }
        throw e;
      }
    }
    if (
      this.user.user_type !== 'photographer'
      && this.$route.name !== 'profile:photography'
    ) {
      // If the user isn't a team photographer and we're not already on
      // the photography tab (which will already trigger loading),
      // try to load the photography feed to check if shot any sets.
      this.photographyFeed.$fetchPosts();
    }
    this.loaded = true;
  },

  methods: {
    numeral,

    async handleMessages() {
      this.loadingConversation = true;
      try {
        const { id } = await Conversation.dispatch(
          '$create',
          { participants: [this.user.id] },
        );
        this.$router.push({ name: 'conversationDetail', params: { id } });
      } finally {
        this.loadingConversation = false;
      }
    },

    handleTip() {
      const recipients = [{ username: this.user.username }];
      this.openTippingDialog({
        recipients,
        tipsURL: this.user.tips_url,
      });
    },

    async loadFollowees($state) {
      try {
        await this.followeeFeed.$fetch();
      } catch (e) {
        $state.error();
        throw e;
      }

      if (this.followeeFeed.objects.length) {
        $state.loaded();
      }
      if (!this.followeeFeed.nextUrl) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },

    async loadFollowers($state) {
      try {
        await this.followerFeed.$fetch();
      } catch (e) {
        $state.error();
        throw e;
      }

      if (this.followerFeed.objects.length) {
        $state.loaded();
      }
      if (!this.followerFeed.nextUrl) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },

    ...mapActions('auth', ['enforceUserEnabled']),
    ...mapActions('entities/tips', ['openTippingDialog']),
    ...mapActions('entities/users', ['toggleProfileDrawer']),
  },
};
</script>
