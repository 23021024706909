import { IsNotEnabledUser } from 'pwa/guards';

export default [
  {
    path: 'pay/',
    name: 'register-payment',
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsNotEnabledUser],
      showPromoVideo: false,
      step: 2,
    },
  },
  {
    path: ':specialOffer?/',
    name: 'register',
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsNotEnabledUser],
      step: 1,
    },
  },
];
