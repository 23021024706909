import { Model } from '@vuex-orm/core';

export default class Stream extends Model {
  static entity = 'streams';

  constructor(object) {
    super(object);
    if (object) {
      this.user_id = object.user_id || object.user.id;
    }
  }

  static fields() {
    return {
      id: this.number(null),
      url: this.string(null),
      created: this.string(),
      ended: this.boolean(false),
      last_active: this.string(),
      tippable: this.boolean(false),
      room_name: this.string(null),
      connect_url: this.string(null),
      cover_url: this.string(null),
      tips_url: this.string(null),
      user_id: this.attr(null),
      user: this.belongsTo('users', 'user_id'),
      viewer_count: this.number(0),
    };
  }

  get $route() {
    return { name: 'viewStream', params: { username: this.user.username } };
  }
}
