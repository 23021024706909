import { Model } from '@vuex-orm/core';

export default class BlogPost extends Model {
  static entity = 'blogPosts';

  static fields() {
    return {
      id: this.number(null),
      text: this.string(''),
      legacy_url: this.string(''),
    };
  }
}
