import { Model } from '@vuex-orm/core';

export default class Profile extends Model {
  static entity = 'profiles';

  static fields() {
    return {
      user_id: this.number(),
      allmylinks_url: this.string(''),
      instagram_url: this.string(''),
      mfc_url: this.string(''),
      onlyfans_url: this.string(''),
      twitter_url: this.string(''),
    };
  }
}
