import { Model } from '@vuex-orm/core';

export default class StatusUpdate extends Model {
  static entity = 'statusUpdates';

  static fields() {
    return {
      id: this.number(null),
      text: this.string(),
    };
  }
}
