import { Model } from '@vuex-orm/core';

import { getConstrainedAspectRatio } from 'pwa/utils/media';

export default class Video extends Model {
  static entity = 'videos';

  static fields() {
    return {
      id: this.number(null),
      cover_image: this.attr({}),
      text: this.string(),
      created: this.string(),
      sources: this.attr(),
    };
  }

  get feedAspectRatio() {
    const { width, height } = this.cover_image || {};
    if (width && height) {
      return getConstrainedAspectRatio(width, height);
    }
    return 16 / 9;
  }
}
