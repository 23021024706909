import { Model } from '@vuex-orm/core';
import { v4 as uuid4 } from 'uuid';

export default class Conversation extends Model {
  static entity = 'conversations';

  static fields() {
    return {
      id: this.number(null),
      latest_activity: this.string(),
      latest_message: this.string(),
      url: this.string(),
      mark_read_url: this.string(),
      mark_unread_url: this.string(),
      messages_url: this.string(),
      blocked: this.boolean(false),
      participants: this.belongsToMany(
        'users',
        'conversationParticipants',
        'conversation_id',
        'user_id',
      ),

      read: this.boolean(false),
      messages: this.hasMany('messages', 'conversation'),
      typing_url: this.string(),

      // Internal fields
      _nextMessagesUrl: this.attr(null),
      _typingPromises: this.attr({}),
    };
  }

  get typing() {
    return !!Object.keys(this._typingPromises).length;
  }

  async loadMoreMessages({ reload = false } = {}) {
    const url = !this._nextMessagesUrl || reload
      ? this.messages_url : this._nextMessagesUrl;
    const data = await this.$store().dispatch(
      'entities/messages/$fetch',
      { url },
    );
    this._nextMessagesUrl = data.next;
    this.$save();

    return data;
  }

  /**
   * Marks current conversation as read
   */
  async markRead() {
    const client = this.$store().getters['api/client'];
    this.read = true;
    this.$save(); // Update local object right away
    this.$dispatch('decrementUnreadCount');

    return client.post(this.mark_read_url); // Make API call
  }

  async sendTypingEvent() {
    const client = this.$store().getters['api/client'];
    return client.post(this.typing_url);
  }

  async receiveTypingEvent() {
    const id = uuid4();
    const promise = new Promise((resolve) => setTimeout(() => {
      delete this._typingPromises[id];
      this.$save();
      resolve();
    }, 5000));
    this._typingPromises[id] = promise;
    return this.$save();
  }

  async clearTypingState() {
    delete this._typingPromises;
    this._typingPromises = {};
    return this.$save();
  }
}
