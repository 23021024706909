<template>
  <div class="fill-height">
    <slot name="app-bar">
      <v-app-bar
        id="app-bar"
        class="pa-0 ma-0"
        v-bind="{ app: true, color: 'white', height: 52, ...appBarOptions }"
      >
        <div
          id="app-bar-content"
          class="mx-auto container-max-width px-1 py-0
                 d-flex flex-column justify-center"
          style="width: 100%; height: 100%"
        >
          <slot name="app-bar-content">
            <div class="d-flex align-center">
              <div>
                <v-img
                  :src="logo"
                  max-width="48"
                  max-height="48"
                  contain
                />
              </div>
              <div
                class="
                  align-center
                  d-flex
                  flex-grow-1
                  justify-center
                  mx-1
                  overflow-hidden
                "
              >
                <v-img
                  :src="banner"
                  max-width="225px"
                  width="100%"
                  contain
                />
              </div>

              <div
                v-if="currentUser.is_authenticated"
                class="d-flex align-center"
              >
                <v-btn
                  v-if="currentUser.is_authenticated"
                  id="profile-btn"
                  icon
                  elevation="0"
                  height="36"
                  width="36"
                  @click="$store.dispatch(
                    'entities/users/toggleProfileDrawer',
                    true,
                  )"
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </div>

              <div
                v-if="!currentUser.is_authenticated"
                class="d-flex justify-end"
              >
                <v-btn
                  id="register-btn"
                  class="text-capitalize mr-2"
                  color="primary"
                  rounded
                  elevation="0"
                  small
                  :to="{ name: 'register', query: $route.query }"
                >
                  Join
                </v-btn>

                <v-btn
                  id="login-btn"
                  class="text-capitalize"
                  color="primary"
                  outlined
                  rounded
                  small
                  :to="{ name: 'login', query: $route.query }"
                >
                  Log In
                </v-btn>
              </div>
            </div>
          </slot>

          <slot name="app-bar-footer" />
        </div>
      </v-app-bar>
    </slot>

    <v-main class="fill-height align-start">
      <slot name="content">
        <v-container
          fluid
          class="base-container main-content fill-height align-start
                justify-center pt-0 mt-0 mb-5"
        >
          <slot />
        </v-container>
      </slot>
    </v-main>

    <slot name="bottom-nav">
      <TheBottomNavigation v-bind="bottomNavOptions" />
    </slot>

    <TheAppUpdateSnackbar />

    <TheGallery />
    <TheProfileDrawer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import banner from './sg-banner.svg';

import logo from '@/../suicidegirls/static/img/logo-solo.svg';

export default {
  name: 'TheBaseLayout',

  props: {
    appBarOptions: {
      type: Object,
      default: null,
    },

    bottomNavOptions: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      banner,
      logo,
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
};
</script>

<style lang="scss">
.base-container {
  max-width: $container-max-width;
  padding: 1rem 0;
}

#app-bar > .v-toolbar__content {
  padding: 0;
}

.v-main__wrap {
  height: 100%;
}
</style>
