import VueRouter from 'vue-router';

import { AnonymousOnly, IsEnabled } from './guards';

import {
  The404Page,
  TheBlockedPage,
  TheCommentPage,
  TheConversationListPage,
  TheConversationPage,
  TheIndexPage,
  TheLoginOrRegisterPage,
  TheLoginPage,
  TheNewConversationPage,
  TheNewPostPage,
  TheNotificationPage,
  ThePostDetailPage,
  ThePreferencesPage,
  TheProfilePage,
  TheRegistrationPage,
  TheSearchPage,
  TheViewStreamPage,
} from 'pwa/pages';
import registrationRoutes from 'pwa/pages/TheRegistrationPage/routes';

export const routes = [
  {
    path: '/',
    name: 'index',
    component: TheIndexPage,
    pathToRegexpOptions: { strict: true },
    children: [
      {
        path: 'p/sets-of-the-day/',
        name: 'index:sotds',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'p/member-review/',
        name: 'index:memberReview',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'p/photos/',
        name: 'index:photos',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'p/videos/',
        name: 'index:videos',
        pathToRegexpOptions: { strict: true },
      },
    ],
  },
  {
    path: '/posts/:id(\\d+)/comments/',
    name: 'postComments',
    component: TheCommentPage,
    pathToRegexpOptions: { strict: true },
    props: (route) => ({ id: Number(route.params.id) }),
    meta: {
      guards: [IsEnabled],
    },
  },
  {
    path: '/posts/:contentType(\\d+)?/:id(\\d+)/',
    name: 'postDetail',
    component: ThePostDetailPage,
    pathToRegexpOptions: { strict: true },
    props: (route) => ({
      id: Number(route.params.id),
      contentType: route.params.contentType
        ? Number(route.params.contentType) : null,
    }),
  },
  {
    path: '/posts/new/',
    name: 'newPost',
    component: TheNewPostPage,
    pathToRegexpOptions: { strict: true },
    props: true,
    meta: {
      guards: [IsEnabled],
    },
  },
  {
    path: '/p/preferences/',
    name: 'preferences',
    component: ThePreferencesPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsEnabled],
    },
  },
  {
    path: '/p/notifications/',
    name: 'notifications',
    component: TheNotificationPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsEnabled],
    },
  },
  {
    path: '/p/conversations/',
    name: 'conversations',
    component: TheConversationListPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsEnabled],
    },
  },
  {
    path: '/conversations/new/',
    name: 'conversationNew',
    component: TheNewConversationPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsEnabled],
    },
    props: true,
  },
  {
    path: '/conversations/:id(\\d+)/',
    name: 'conversationDetail',
    component: TheConversationPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [IsEnabled],
    },
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: '/p/login/',
    name: 'login',
    component: TheLoginPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [AnonymousOnly],
    },
  },
  {
    path: '/p/loginOrRegister/',
    name: 'loginOrRegister',
    component: TheLoginOrRegisterPage,
    pathToRegexpOptions: { strict: true },
  },
  {
    path: '/p/blocked/',
    name: 'blocked',
    component: TheBlockedPage,
    pathToRegexpOptions: { strict: true },
    props: true,
  },
  {
    path: '/p/register/',
    component: TheRegistrationPage,
    pathToRegexpOptions: { strict: true },
    meta: {
      guards: [AnonymousOnly],
    },
    beforeEnter() {
      // Hack in redirect to main site for now,
      // Stripe is dead.
      window.location.assign('https://www.suicidegirls.com/join/');
    },
    children: registrationRoutes,
    props: (route) => ({
      showPromoVideo: route.meta.showPromoVideo !== false,
      step: route.meta.step,
    }),
  },
  {
    path: '/p/search/',
    name: 'search',
    component: TheSearchPage,
    pathToRegexpOptions: { strict: true },
  },
  {
    path: '/:username/',
    name: 'profile',
    component: TheProfilePage,
    pathToRegexpOptions: { strict: true },
    props: true,
    children: [
      {
        path: 'photosets/',
        name: 'profile:photosets',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'photos/',
        name: 'profile:photos',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'videos/',
        name: 'profile:videos',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'blogs/',
        name: 'profile:blogs',
        pathToRegexpOptions: { strict: true },
      },
      {
        path: 'photography/',
        name: 'profile:photography',
        pathToRegexpOptions: { strict: true },
      },
    ],
  },
  {
    path: '/:username/live/',
    name: 'viewStream',
    component: TheViewStreamPage,
    pathToRegexpOptions: { strict: true },
    props: true,
  },
  {
    path: '/404/',
    name: 'notFound',
    component: The404Page,
    pathToRegexpOptions: { strict: true },
  },
  {
    path: '*',
    component: The404Page,
  },
];

/**
 * Block until appState is no longer loading.
 */
async function waitForLoad(store) {
  if (['created', 'loading'].includes(store.getters.appState)) {
    await new Promise((r) => setTimeout(r, 50));
    return waitForLoad(store);
  }
  return null;
}

/**
 * Returns router for app
 * Requires a store instance to add navigation guards
 */
export function getRouter(store) {
  const router = new VueRouter({
    routes,

    mode: 'history',

    /**
     * Scroll to top of page on route change, unless hitting the
     * the route changed as a result of the use of back / forward button
     */
    scrollBehavior: async (to, from, savedPosition) => {
      if (savedPosition) {
        // Lame workaround. See "Async Scrolling"
        // https://router.vuejs.org/guide/advanced/scroll-behavior.html
        await new Promise((r) => setTimeout(r, 500));
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });

  /**
   * Fetches API routes, current user before vue-router
   * loads the app base.
   */
  router.beforeEach(async (to, from, next) => {
    await waitForLoad(store);

    if (store.getters['pageUnload/listening']) {
      const continueNav = await store.dispatch('pageUnload/openDialog');
      return next(continueNav);
    }

    const user = store.getters['auth/currentUser'];
    const guards = to.meta.guards || [];
    for (const Guard of guards) { // eslint-disable-line no-restricted-syntax
      const guard = new Guard(to, from, next, user);
      if (!guard.canNavigate()) {
        return guard.failCallback();
      }
    }

    store.dispatch('router/setRoute', to);

    if (to.query.check_messages === '1') {
      store.dispatch('flashMessages/checkMessages');
    }

    return next();
  });

  return router;
}

export default {};
