<template>
  <v-app>
    <TheOfflineBar />
    <slot
      v-if="appState === 'loaded'"
    >
      <router-view />
    </slot>
    <ThePageLoader />
    <TheToaster />

    <v-overlay
      :absolute="absolute"
      color="white"
      opacity="1"
      :value="!(['loaded', 'failed'].includes(appState))"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>

    <div
      v-if="appState === 'failed'"
      class="failed fill-height d-flex flex-column align-center justify-center"
    >
      <div>
        <v-img
          :src="logo"
          width="75"
          height="97"
        />
      </div>
      <p class="my-3">
        The app could not be loaded
      </p>

      <v-btn
        id="reload"
        @click="reload"
      >
        <v-icon left>
          mdi-refresh
        </v-icon>
        Try again
      </v-btn>
    </div>

    <TheMembersOnlySheet />
    <TheRenewSubscriptionSheet />
    <TheTippingDialog />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import logo from '@/../suicidegirls/static/img/logo-solo.svg';

/**
 * @displayName The app shell
 */
export default {
  name: 'TheAppShell',

  props: {
    /**
     * Set to true to contain in an element (e.g. styleguide)
     */
    absolute: {
      type: Boolean,
      default: false,
    },

    /**
     * Overrides the "try again" callback
     */
    reloadAction: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      logo,
    };
  },

  computed: {
    ...mapGetters(['appState']),
  },

  async created() {
    if (this.appState === 'created') {
      await this.$store.dispatch('loadApp');
    }
  },

  methods: {
    async reload() {
      if (this.reloadAction) {
        return this.reloadAction();
      }
      return this.$store.dispatch('loadApp');
    },
  },
};
</script>
