<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar />
    </template>

    <div slot="default">
      <div class="d-flex fill-height align-center justify-center flex-column">
        <h1 class="text-h1 font-weight-bold primary--text mb-5">
          Oops!
        </h1>
        <img
          class="mb-4"
          :src="logo"
          height="200"
        >
        <p>Not found</p>
      </div>
    </div>
  </TheBaseLayout>
</template>

<script>
import logo from '@/../suicidegirls/static/img/logo-solo.svg';

export default {
  name: 'The404Page',

  data() {
    return { logo };
  },
};
</script>
