<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar />
    </template>

    <v-container
      fluid
      class="text-center"
    >
      <v-icon size="200">
        mdi-block-helper
      </v-icon>

      <div
        class="mt-5 text-body-1"
      >
        <span v-if="username">
          You have been blocked by {{ username }}.
        </span>
        <span v-else>
          You have been blocked from seeing this content
        </span>
      </div>
    </v-container>
  </TheBaseLayout>
</template>

<script>
export default {
  name: 'TheBlockedPage',

  props: {
    username: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
