<template>
  <TheBaseLayout>
    <div
      slot="app-bar-content"
      class="d-flex align-center fill-height"
    >
      <TheNavAppBar
        left-cols="auto"
        center-cols="10"
        right-cols="0"
      >
        <div
          slot="center"
          class="ml-2"
        >
          <UserSearch
            item-value="username"
            width="100%"
            @input="goToProfile"
          />
        </div>
      </TheNavAppBar>
    </div>

    <v-container
      class="px-0"
      fluid
    >
      <UserSlider
        v-if="lastActiveFeed"
        id="last-active"
        class="mb-5"
        :users="lastActive"
        :loading="lastActiveFeed._pending"
        title="Recently active"
      />

      <UserSlider
        v-if="mostFollowersFeed"
        id="most-followers"
        :loading="mostFollowersFeed._pending"
        :users="mostFollowers"
        title="Popular models"
      />

      <UserSlider
        v-if="followeesFeed"
        id="followees"
        :loading="followeesFeed._pending"
        :users="followees"
        :show-follow="false"
        title="Following"
      />
    </v-container>
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

import logo from '@/../suicidegirls/static/img/logo-solo.svg';
import { UserFeed } from 'pwa/models';

export default {
  name: 'TheSearchPage',

  data() {
    return {
      followees: [],
      followeesFeed: null,
      logo,
      lastActive: [],
      lastActiveFeed: null,
      mostFollowers: [],
      mostFollowersFeed: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    this.getLastActiveUsers();
    this.getMostFollowedUsers();
    if (this.currentUser.is_authenticated) {
      this.getFolloweeUsers();
    }
  },

  beforeDestroy() {
    this.followeesFeed.$delete();
    this.lastActiveFeed.$delete();
    this.mostFollowersFeed.$delete();
  },

  methods: {
    async getFolloweeUsers() {
      this.followeesFeed = await UserFeed.dispatch('getOrCreate', {
        following: true,
      });
      await this.followeesFeed.$fetch();
      this.followees = this.followeesFeed.objects;
    },

    async getLastActiveUsers() {
      this.lastActiveFeed = await UserFeed.dispatch('getOrCreate', {
        following: false,
        user_type: 'active_model',
        ordering: '-last_active',
      });
      await this.lastActiveFeed.$fetch();
      this.lastActive = this.lastActiveFeed.objects;
    },

    async getMostFollowedUsers() {
      this.mostFollowersFeed = await UserFeed.dispatch('getOrCreate', {
        following: false,
        user_type: 'active_model',
        ordering: '-follower_count',
      });
      await this.mostFollowersFeed.$fetch();
      this.mostFollowers = this.mostFollowersFeed.objects;
    },

    goToProfile(username) {
      this.$router.push({ name: 'profile', params: { username } });
    },
  },
};
</script>
