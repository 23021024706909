<template>
  <TheBaseLayout>
    <template #app-bar-content>
      <TheNavAppBar>
        <v-tabs
          id="notification-tabs"
          slot="center"
          v-model="tab"
          centered
          height="52"
        >
          <v-tab
            v-for="(obj, index) in filters"
            :key="index"
            @click="$vuetify.goTo(0)"
          >
            {{ obj.label }}
          </v-tab>
        </v-tabs>
      </TheNavAppBar>
    </template>

    <v-container fluid>
      <v-list two-line>
        <div
          v-for="notification in notifications"
          :key="notification.id"
        >
          <Notification :notification="notification" />
          <v-divider />
        </div>
      </v-list>
      <infinite-loading
        :identifier="tab"
        @infinite="loadNext"
      />
    </v-container>
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

import { Notification } from 'pwa/models';

export default {
  name: 'TheNotificationPage',

  data() {
    return {
      tab: 0,
      filters: [
        { label: 'All', value: null, nextUrl: null },
        { label: 'Likes', value: 'likes', nextUrl: null },
        { label: 'Comments', value: 'comments', nextUrl: null },
        { label: 'Follows', value: 'follows', nextUrl: null },
        { label: 'Tips', value: 'tips', nextUrl: null },
      ],
    };
  },

  computed: {
    filter() {
      return this.filters[this.tab];
    },

    notifications() {
      let notifications = Notification.query().with('actors');
      const filter = this.filter.value;
      if (filter) {
        notifications = notifications.where('type', filter);
      }
      return notifications.orderBy('created', 'desc').get();
    },

    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    Notification.dispatch('$clearNotifications');
  },

  methods: {
    async loadNext($state) {
      const url = this.filter.nextUrl;
      const type = this.filter.value;

      try {
        const data = await Notification.dispatch('$fetch', { url, type });
        if (this.notifications.length) {
          $state.loaded();
        }

        this.filter.nextUrl = data.next;
        if (this.filter.nextUrl === null) {
          $state.complete();
        }
      } catch (e) {
        $state.error();
        throw e;
      }
    },
  },
};
</script>
