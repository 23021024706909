<template>
  <v-container
    fluid
    class="container-max-width mx-auto"
  >
    <v-alert
      v-if="specialOfferPlan"
      id="special-offer"
      border="left"
      color="primary"
      colored-border
      icon="mdi-tag-heart"
      elevation="0"
      dense
    >
      <h3
        v-text="specialOfferPlan.display"
      />
    </v-alert>
    <v-row class="d-flex align-center my-2">
      <v-col>
        <v-divider />
      </v-col>

      <v-col
        cols="auto"
        class="text-uppercase text-center font-weight-medium text-caption"
      >
        Or sign up with email
      </v-col>

      <v-col>
        <v-divider />
      </v-col>
    </v-row>

    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <v-form
        :disabled="loading"
        novalidate
        @submit.prevent="handleSubmit(submit)"
      >
        <v-expand-transition>
          <v-alert
            v-if="showGlobalFail"
            type="error"
            class="error mt-5"
          >
            An unexpected error has occurred.
          </v-alert>
        </v-expand-transition>

        <validation-provider
          v-slot="{ errors }"
          vid="email"
          name="Email"
          rules="required|email"
        >
          <v-text-field
            id="email"
            v-model="email"
            :error-messages="errors"
            autocomplete="email"
            label="E-mail"
            outlined
            required
            type="email"
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          vid="username"
          name="Username"
          rules="required|min:3|max:40"
        >
          <v-text-field
            id="username"
            v-model="username"
            :counter="40"
            :error-messages="errors"
            autocapitalize="none"
            autocorrect="off"
            label="Username"
            maxlength="40"
            outlined
            required
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          vid="password"
          name="Password"
          rules="required|min:8|max:128"
        >
          <PasswordField
            id="password"
            v-model="password"
            :error-messages="errors"
            autocomplete="new-password"
            maxlength="128"
            outlined
            required
          />
        </validation-provider>

        <v-btn
          id="submit-btn"
          :loading="loading"
          block
          class="white--text"
          color="green"
          type="submit"
          x-large
        >
          Next
        </v-btn>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { extend, setInteractionMode } from 'vee-validate';
import {
  email as emailRule,
  max,
  min,
  required,
} from 'vee-validate/dist/rules';

import { APIError, InvalidError } from 'pwa/exceptions';
import { Plan } from 'pwa/models';

setInteractionMode('eager');

extend('email', emailRule);
extend('required', {
  message: '{_field_} is required.',
  ...required,
});
extend('min', {
  message: '{_field_} must be at least {length} characters.',
  ...min,
});
extend('max', {
  message: '{_field_} must not exceed {length} characters.',
  ...max,
});

export default {
  name: 'TheRegistrationPageInitialStep',

  props: {
    submitAction: {
      type: Function,
      async default(data) {
        await this.$store.dispatch('entities/joins/$create', data);
        this.$router.replace({
          name: 'register-payment',
          query: this.$route.query,
        });
      },
    },
  },

  data() {
    return {
      email: '',
      loading: false,
      password: '',
      specialOfferPlan: null,
      showGlobalFail: false,
      showPassword: false,
      username: '',
    };
  },

  async created() {
    const { specialOffer } = this.$route.params;
    if (this.$store.getters['auth/currentUser'].is_authenticated) {
      const query = { ...this.$route.query, specialOffer };
      this.$router.push({ name: 'register-payment', query });
      return;
    }
    if (specialOffer) {
      try {
        await Plan.dispatch('$fetch', specialOffer);
      } catch (e) {
        if ((e instanceof InvalidError) && 'special_offer' in e.errors) {
          // Redirect if special offer is not valid
          this.$router.replace({ name: 'register', params: {} });
          return;
        }
        throw e;
      }
      const plans = Plan.query().where('special_offer', true).get();
      this.specialOfferPlan = plans.length ? plans[0] : null;
    }
  },

  methods: {
    async submit() {
      const {
        email,
        username,
        password,
      } = this;
      this.loading = true;
      this.showGlobalFail = false;
      this.$emit('submit');
      const data = { email, username, password };

      if (this.specialOfferPlan) {
        data.special_offer = this.specialOfferPlan.specialOfferCode;
      }

      const { token } = this.$route.query;
      if (token) {
        data.tracking_token = token;
      }

      try {
        await this.submitAction(data);
      } catch (e) {
        if (e instanceof InvalidError && Object.keys(e.errors).length) {
          this.$refs.observer.setErrors(e.errors);
        } else {
          this.showGlobalFail = true;
        }
        if (!(e instanceof APIError)) {
          throw e;
        }
      } finally {
        this.loading = false;
        this.$emit('done');
      }
    },
  },
};
</script>
