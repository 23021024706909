<template>
  <TheBaseLayout>
    <template #app-bar>
      <v-app-bar app>
        <v-btn
          class="fill-height"
          block
          tile
          x-large
          color="primary"
          :to="newMessageRoute"
          width="100%"
          height="100%"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          New message
        </v-btn>
      </v-app-bar>
    </template>

    <v-container
      slot="default"
      class="mx-0 px-0"
      fluid
    >
      <v-list>
        <div
          v-for="conversation in conversations"
          :key="conversation.id"
        >
          <ConversationListItem
            :participants="conversation.participants"
            :text="conversation.latest_message"
            :latest-activity="new Date(conversation.latest_activity)"
            :to="{
              name: 'conversationDetail',
              params: { id: conversation.id },
            }"
            :read="conversation.read"
          />
          <v-divider />
        </div>
      </v-list>

      <div>
        <infinite-loading
          class="pb-5"
          @infinite="loadNext"
        >
          <div slot="no-more" />
          <div slot="no-results">
            <p>You have not sent or received any messages yet.</p>
            <v-btn
              color="primary"
              small
              :to="newMessageRoute"
            >
              Compose a new message
            </v-btn>
          </div>
        </infinite-loading>
      </div>
    </v-container>
  </TheBaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';

import ConversationListItem from 'pwa/components/ConversationListItem';
import Conversation from 'pwa/models/Conversation';

export default {
  name: 'TheConversationListPage',

  components: {
    ConversationListItem,
  },

  data: () => ({
    newMessageRoute: { name: 'conversationNew' },
  }),

  computed: {
    conversations: () => (
      Conversation.query()
        .with('participants')
        .orderBy('latest_activity', 'desc')
        .get()
    ),
    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    Conversation.dispatch('acknowledgeUnreadNotifications');
  },

  methods: {
    async loadNext($state) {
      try {
        const data = await Conversation.dispatch('$fetchNext');
        $state.loaded();

        this.nextUrl = data.next;
        if (!this.nextUrl) {
          $state.complete();
        }
      } catch (e) {
        $state.error();
        throw e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar__content {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>
