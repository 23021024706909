import { Model } from '@vuex-orm/core';
import { v4 as uuid4 } from 'uuid';

export default undefined;

/**
 * Lookup model for feeds & posts M2M
 */
export class PostFeed extends Model {
  static entity = 'postFeeds';

  static primaryKey = ['post_id', 'feed_id'];

  static fields() {
    return {
      post: this.belongsTo('posts', 'post_id'),
      feed: this.belongsTo('feeds', 'feed_id'),
      post_id: this.attr(null),
      feed_id: this.attr(null),
    };
  }
}

export class Feed extends Model {
  static entity = 'feeds';

  static fields() {
    return {
      id: this.attr(() => uuid4()),
      url: this.string(null).nullable(),
      _posts: this.belongsToMany(
        'posts',
        PostFeed,
        'feed_id',
        'post_id',
      ),

      // API Filters
      created_after: this.attr(null).nullable(),
      type: this.attr(null).nullable(), // Can be Array or String
      user_type: this.attr(null).nullable(),
      username: this.string(null).nullable(),
      ordering: this.string(null).nullable(),
      photographer_username: this.string(null).nullable(),
      nextUrl: this.string(null).nullable(),

      // Used to throttle requests
      _pending: this.attr(null).nullable(),
    };
  }

  /**
   * Use a getter to fetch related post objects to compensate
   * for the belongsToMany not updating in real-time
   */
  get posts() {
    const feed = this.$query()
      .with(['_posts', '_posts.users_about'])
      .find(this.id);
    if (!feed) {
      return [];
    }
    return feed._posts;
  }

  async $fetchPosts() {
    // If an existing request for this feed is pending, return and ignore
    // this call.
    if (this._pending) {
      return this._pending;
    }

    const filters = Object.fromEntries(
      this.$getters('apiFilters').map((filter) => [filter, this[filter]]),
    );

    const request = this.$store().dispatch(
      'entities/posts/$fetch', {
        ...filters,
        feed: this,
        url: this.nextUrl || this.url,
      },
    );

    try {
      this._pending = request;
      const data = await request;
      this.nextUrl = data.next;
    } finally {
      this._pending = null;
      await this.$save();
    }
    return request;
  }
}
