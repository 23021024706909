import { Model } from '@vuex-orm/core';

export default class Tip extends Model {
  static entity = 'tips';

  static fields() {
    return {
      id: this.number(null),
      amount: this.number(null),
      public: this.boolean(false),
      text: this.string(),
      user_to: this.number(null),
      sender: this.string(null),
    };
  }
}
