import { Model } from '@vuex-orm/core';

/**
 * Pivot model ("through table") for M2M relationship between
 * Post and User models
 */
export default class PostUserAbout extends Model {
  static entity = 'postUserAbout';

  static primaryKey = ['user_id', 'post_id'];

  static fields() {
    return {
      user_id: this.attr(null),
      post_id: this.attr(null),
    };
  }
}
